import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Hidden,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { ScheduledemoContext } from "../../App";
import { ref, set } from "firebase/database";
import { db } from "../../firebase.config";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { countrycode } from "../../App"; 
import phone from "phone";
import axios from "axios";
import md5 from "md5";

// SuiteCRM API Credentials and Endpoints
const suiteCRMUrl = "https://lead.redianglobal.com"; // Replace with your SuiteCRM URL
const loginEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;

// SuiteCRM Login Data
const loginData = {
  method: "login",
  input_type: "JSON",
  response_type: "JSON",
  rest_data: JSON.stringify({
    user_auth: {
      user_name: "DriverShaab",
      password: md5("VhmQhaUrVklpgrK"),
      version: "1",
    },
    application_name: "MyApp",
  }),
};

// Function to get the access token (session ID)
const getAccessToken = async () => {
  try {
    const response = await axios.post(loginEndpoint, new URLSearchParams(loginData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    if (response.data && response.data.id) {
      console.log("Access Token (Session ID):", response.data.id);
      return response.data.id;
    } else {
      console.log("Error logging in:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Login failed:", error);
    return null;
  }
};

// Function to create a lead in SuiteCRM
const createLead = async (leadData) => {
  const accessToken = await getAccessToken(); // Get session ID

  if (!accessToken) {
    console.error("Failed to get access token");
    return;
  }

  const createLeadEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;

  const leadDataPayload = {
    method: "set_entry",
    input_type: "JSON",
    response_type: "JSON",
    rest_data: JSON.stringify({
      session: accessToken,
      module_name: "Leads",
      name_value_list: { 
        first_name: { name: "first_name", value: leadData.first_name },
        salutation: { name: "salutation", value: leadData.salutation },
        last_name: { name: "last_name", value: leadData.last_name },
        email1: { name: "email1", value: leadData.email },
        phone_work: { name: "phone_mobile", value: leadData.contact },
        description: { name: "description", value: leadData.additionalInfo },
        state_c: { name: "primary_address_state", value: leadData.state },
        city_c: { name: "primary_address_city", value: leadData.city },
        lead_source: { name: "lead_source", value: "B2B_Request_Form" },
      },
    }),
  };

  try {
    const response = await axios.post(createLeadEndpoint, new URLSearchParams(leadDataPayload), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    console.log("Lead Created Successfully:", response.data);
  } catch (error) {
    console.error("Error creating lead:", error);
  }
};

const SchuduleDemoForm = () => {
  const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const salutations = [
    "Mr.",
    "Ms.",
    "Mrs.",
    "Miss",
    "Dr.", 
    "Prof.",
  ];


  const { scheduledemo, setScheduledemo } = useContext(ScheduledemoContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (scheduledemo) {
      document.getElementById("scheduledemo").scrollIntoView();
      window.scrollBy(0, -80);
      setScheduledemo(false);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    salutation:"",
    email: "",
    ccode: "+91",
    contactNo: "",
    city: "",
    state: "",
    additionalInfo: "",
  });

  const handleChange = (event) => {
    if (
      event.target.name === "contactNo" &&
      event.target.value.toString().length > 10
    )
      return;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phone(formData.ccode + formData.contactNo).isValid) {
      alert('Enter Valid Contact Number.');
      return;
    }
    if (
      !formData.name &&
      EmailValidator.validate(formData.email) &&
      !formData.contactNo
    ) {
      return;
    }

    // Prepare data for SuiteCRM lead creation salutation
    const leadData = {
      first_name: formData.first_name,
      salutation: formData.salutation,
      last_name: formData.last_name, // Add last name field if you need it
      email: formData.email,
      contact: formData.contactNo,
      additionalInfo: formData.additionalInfo,
      state: formData.state,
      city: formData.city,
    };

    await createLead(leadData); // Create the lead in SuiteCRM

    // Save data to Firebase
    const id = Date.now();
    const useref = ref(db, "DemoRequests/" + id);
    set(useref, {
      ...formData,
      timeStamp: new Date().toLocaleString(),
    });

    // Reset form and navigate
    setFormData({
      name: "",
      email: "",
      salutation:"",
      ccode: "+91",
      contactNo: "",
      city: "",
      state: "",
      additionalInfo: "",
    });

    navigate("/querysubmit");
  };

  return (
    <Box display={"flex"} justifyContent={"center"} mt={2} id="scheduledemo">
      <Grid container maxWidth={"1200px"}>
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          display={"flex"}
          justifyContent={{ lg: "end", xs: "center" }}
          alignItems={"center"}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
              width: "90vw",
              maxWidth: "500px",
            }}
          >
            <div className="joinasdriver_form_container">
              <div className="joinasdriver_heading">
                <p className="join-as-driver-heading">
                  Schedule a demo with us
                </p>
                <p className="join-as-driver-sub-heading">
                  At DriverShaab, we provide PAN India level B2B services to
                  corporate and logistics companies.
                </p>
              </div>

              <div className="joinasdriver_form">
                <form
                  onSubmit={handleSubmit}
                  className="scheduledemo_form"
                  style={{ width: "100%" }}
                >
                 <Box sx={{ display: "flex", gap: 0.5 }}>
<FormControl sx={{ width: "30%" }}>
    <InputLabel 
    id="demo-select-small-label4" 
    sx={{ fontSize: "15px" }}>
     
    </InputLabel>
    <Select
      required
      labelId="demo-select-small-label4"
      id="demo-select-small-label4"
      value={formData.salutation}
      name="salutation"
      onChange={handleChange}
          variant="outlined"
    sx={{ fontSize: "15px" }}
    >
       {salutations.map((salutation) => (
                        <MenuItem key={salutation} value={salutation}>
                          {salutation}
                        </MenuItem>
                      ))}
    </Select>
  </FormControl>

  {/* First Name Input Field */}
  <TextField
    label="First Name"
    required
    name="first_name"
    value={formData.first_name}
    onChange={handleChange}
    variant="outlined"
    fullWidth
    sx={{
      fontSize: "15px",
      "& .MuiFormLabel-root": { fontSize: "15px" },
      "& .MuiInputBase-root": { fontSize: "15px" }, // Ensures consistency
    }}
  />
</Box>

                  <TextField
                    label="Last Name"
                    required
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    variant="outlined"
                    fullWidth
                    sx={{fontSize: "15px",
                      "& .MuiFormLabel-root": { fontSize: "15px" },
                      "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
                        fontSize: "15px",
                      },
                    }}
                  />

                  <TextField
                    label="Email"
                    variant="outlined"
                    required
                    fullWidth
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    sx={{fontSize: "15px",
                      "& .MuiFormLabel-root": { fontSize: "15px" },
                      "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
                        fontSize: "15px",
                      },
                    }}
                  />
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    <FormControl sx={{ width: "30%" }}>
                      <InputLabel
                        id="demo-select-small-label3"
                        sx={{ fontSize: "15px" }}
                      >
                        Country Code
                      </InputLabel>
                      <Select
                        required
                        labelId="demo-select-small-label3"
                        id="demo-select-small3"
                        value={formData.ccode}
                        // fullWidth
                        label="Country Code"
                        name="ccode"
                        onChange={handleChange}
                        sx={{ fontSize: "15px" }}
                      >
                        {/* <MenuItem value={''}>{'state'}</MenuItem> */}
                        {countrycode.map((code) => (
                          <MenuItem key={code.code} value={code.code}>
                            {code.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Contact No."
                      variant="outlined"
                      required
                      fullWidth
                      type="number"
                      name="contactNo"
                      value={formData.contactNo}
                      onChange={handleChange}
                      sx={{fontSize: "15px",
                        "& .MuiFormLabel-root": { fontSize: "15px" },
                        "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root":
                          {
                            fontSize: "15px",
                          },
                      }}
                    />
                  </Box>
                  <FormControl>
                    <InputLabel
                      id="demo-select-small-label2"
                      sx={{ fontSize: "15px" }}
                    >
                      State
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-select-small-label2"
                      id="demo-select-small2"
                      value={formData.state}
                      fullWidth
                      label="State"
                      name="state"
                      onChange={handleChange}
                      sx={{ fontSize: "15px" }}
                    >
                      {/* <MenuItem value={''}>{'state'}</MenuItem> */}
                      {states.map((state) => (
                        <MenuItem key={state} value={state}>
                          {state}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="City"
                    variant="outlined"
                    required
                    fullWidth
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    sx={{fontSize: "15px",
                      "& .MuiFormLabel-root": { fontSize: "15px" },
                      "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
                        fontSize: "15px",
                      },
                    }}
                  />

                  <TextField
                    label="your message..."
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                    rows={4}
                    name="additionalInfo"
                    value={formData.additionalInfo}
                    onChange={handleChange}
                    sx={{fontSize: "15px",
                      "& .MuiFormLabel-root": { fontSize: "15px" },
                      "& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root": {
                        fontSize: "15px",
                      },
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    className="join-as-driver-btn"
                    sx={{ fontSize: "15px", textTransform: "none" }}
                  >
                    Schedule Demo
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SchuduleDemoForm;
