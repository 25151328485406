import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { ref, set } from "firebase/database";
import { db } from "../firebase.config";
import { useNavigate } from "react-router-dom";
import { phone } from "phone";
import axios from "axios";
import md5 from "md5";

const suiteCRMUrl = "https://drivershaab.com/crm"; // Replace with your SuiteCRM URL
const loginEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;

const loginData = {
  method: "login",
  input_type: "JSON",
  response_type: "JSON",
  rest_data: JSON.stringify({
    user_auth: {
      user_name: "Mobileapplication",
      password: md5("^c?sR6X58kKc&p93fds"),
      version: "1",
    },
    application_name: "MyApp",
  }),
};

const getAccessToken = async () => {
  try {
    const response = await axios.post(loginEndpoint, new URLSearchParams(loginData), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    if (response.data && response.data.id) {
      console.log("Access Token (Session ID):", response.data.id);
      return response.data.id;
    } else {
      console.log("Error logging in:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Login failed:", error);
    return null;
  }
};

const createLead = async (leadData) => {
  const accessToken = await getAccessToken(); // Get session ID

  if (!accessToken) {
    console.error("Failed to get access token");
    return;
  }

  const createLeadEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;

  const leadDataPayload = {
    method: "set_entry",
    input_type: "JSON",
    response_type: "JSON",
    rest_data: JSON.stringify({
      session: accessToken,
      module_name: "Leads",
      name_value_list: {
        first_name: { name: "first_name", value: leadData.name },
        last_name: { name: "last_name", value: leadData.last_name },
        email1: { name: "email1", value: leadData.email },
        phone_work: { name: "phone_work", value: leadData.contact },
        description: { name: "description", value: leadData.additionalInfo },
        state_c: { name: "state_c", value: leadData.state },
        city_c: { name: "city_c", value: leadData.city },
        lead_source: { name: "lead_source", value: "B2B_Request_Form" }
       
      },
    }),
  };

  try {
    const response = await axios.post(createLeadEndpoint, new URLSearchParams(leadDataPayload), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    console.log("Lead Created Successfully:", response.data);
  } catch (error) {
    console.error("Error creating lead:", error);
  }
};

const B2BForm = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    ccode: "+91",
    contactNo: "",
    city: "",
    state: "",
    additionalInfo: "",
  
  });

  const handleChange = (event) => {
    if (event.target.name === "contactNo" && event.target.value.toString().length > 10)
      return;
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!phone(formData.ccode + formData.contactNo).isValid) {
      alert("Enter Valid Contact Number.");
      return;
    }
    if (!formData.name || !formData.email || !formData.contactNo) {
      alert("Please fill all required fields.");
      return;
    }

    // Save to Firebase
    const id = Date.now();
    const useref = ref(db, "Applications/" + id);
    await set(useref, {
      ...formData,
      timeStamp: new Date().toLocaleString(),
    });

    // Save to SuiteCRM Leads module
    await createLead({
      name: formData.name,
      last_name: formData.last_name,
      email: formData.email,
      contact: formData.contactNo,
      additionalInfo: formData.additionalInfo,
      city: formData.city,
      state: formData.state,
      roles: formData.roles,
    });

    setFormData({
      name: "",
      last_name: "",
      email: "",
      ccode: "+91",
      contactNo: "",
      city: "",
      state: "",
      additionalInfo: "",
    
    });

    navigate("/querysubmit");
  };

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={3} mb={5}>
      <Grid container maxWidth={"1200px"}>
        <Grid item lg={12} md={12} xs={12} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <div className="joinasdriver_form_container" style={{ width: "90vw", maxWidth: "500px" }}>
            <p className="join-as-driver-heading">B2B Request Form</p>

            <div className="joinasdriver_form">
              <form onSubmit={handleSubmit} className="scheduledemo_form" style={{ width: "100%" }}>
                <TextField
                  required
                  label="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

<TextField
                  required
                  label="Last Name"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

                <TextField
                  required
                  type="email"
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

                <TextField
                  required
                  label="Contact No"
                  name="contactNo"
                  value={formData.contactNo}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

                <TextField
                  label="State"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

                       

                <TextField
                  label="City"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  fullWidth
                  sx={{ fontSize: "15px" }}
                />

                
                <TextField
                  required
                  label="Additional Info"
                  name="additionalInfo"
                  value={formData.additionalInfo}
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{ fontSize: "15px" }}
                />

                <Button 
  type="submit" 
  variant="contained" 
  sx={{ 
    fontSize: "14px", 
    textTransform: "none", 
    backgroundColor: "#fb561e", 
    '&:hover': { 
      backgroundColor: "#e54b1a" // Optional: Change background on hover to a darker shade
    }
  }}
>
  Submit
</Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default B2BForm;
