import React, { useContext, useEffect,useState } from "react";
import "../Assets/css/style.css";
import "../Assets/css/bootstrap.css";
import "../Assets/css/responsive.css";
import { ref, set } from "firebase/database";
import { db } from "../firebase.config";
import GoogleMapcom from "./GoogleMapcom";
import { useNavigate } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import axios from "axios";
import md5 from "md5";
import {
  Box,
  TextField,
  Typography,
  Button,
  Hidden,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
 
const ContactSection = () => {
   const states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const navigate = useNavigate();
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
   // Pooja Start
 
   const suiteCRMUrl = "https://lead.redianglobal.com"; // Replace with your SuiteCRM URL
   const loginEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;
   
   const loginData = {
     method: "login",
     input_type: "JSON",
     response_type: "JSON",
     rest_data: JSON.stringify({
       user_auth: {
        user_name: "DriverShaab",
        password: md5("VhmQhaUrVklpgrK"),
         version: "1",
       },
       application_name: "MyApp",
     }),
   };
   
   const getAccessToken = async () => {
     try {
       const response = await axios.post(loginEndpoint, new URLSearchParams(loginData), {
         headers: { "Content-Type": "application/x-www-form-urlencoded" },
       });
   
       if (response.data && response.data.id) {
         console.log("Access Token (Session ID):", response.data.id);
         return response.data.id;
       } else {
         console.log("Error logging in:", response.data);
         return null;
       }
     } catch (error) {
       console.error("Login failed:", error);
       return null;
     }
   };
   
   getAccessToken(); // Call this function to get the token
   const createLead = async (leadData) => {
    const accessToken = await getAccessToken(); // Get session ID
 
    if (!accessToken) {
      console.error("Failed to get access token");
      return;
    }
 
    const createLeadEndpoint = `${suiteCRMUrl}/service/v4_1/rest.php`;
 
    const leadDataPayload = {
      method: "set_entry",
      input_type: "JSON",
      response_type: "JSON",  
      rest_data: JSON.stringify({
        session: accessToken,
        module_name: "Leads",
        name_value_list: {
          first_name: { name: "first_name", value: leadData.name },
          last_name: { name: "last_name", value: leadData.last_name },
          email1: { name: "email1", value: leadData.email },
          phone_work: { name: "phone_mobile", value: leadData.contact },
           city: { name: "primary_address_city", value: leadData.city },
          stae: { name: "primary_address_state", value: leadData.state },
          description: { name: "description", value: leadData.message },
          lead_source: { name: "lead_source", value: "Contact_Us" }
        },
      }),
    };
 
    try {
      const response = await axios.post(createLeadEndpoint, new URLSearchParams(leadDataPayload), {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      });
 
      console.log("Lead Created Successfully:", response.data);
    } catch (error) {
      console.error("Error creating lead:", error);
    }
  };
 
 
   
  // Pooja End
  const submit = (e) => {
    e.preventDefault();
    if (!name && !email && !message) {
      return;
    }
  
    // Prepare data for SuiteCRM
    const leadData = {
      name,
      last_name,
      email,
      message,
      contact,
       city,
    state,
    };
 
    createLead(leadData);
    
    const id = Date.now();
    const useref = ref(db, "Querys/" + id);
    set(useref, {
      name,
      last_name,
      email,
      message,
      contact,
       city,
    state,
      timeStamp: new Date().toLocaleString(),
    });
    setEmail("");
    setMessage("");
    setName("");
    setContact("");
    navigate("/querysubmit");
 
    console.log("succsesfull");
  };
 
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      console.log("Copied!");
    } catch (err) {
      console.log("Failed to copy!");
    }
  };
  return (
    <>
      {/* Page Title */}
      <div
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <section className="page-title" style={{ maxWidth: "1200px" }}>
          <div className="auto-container">
            <h1>CONTACT US</h1>
            <div className="bread-crumb-outer">
              <ul className="bread-crumb clearfix">
                {/* <li><a href="index.html">Home</a></li> */}
                <li className="contact_subheading">
                  Let us know how we can help
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* End Page Title */}
 
        {/* Contact Section */}
        <div className="contact-section">
          <div className="auto-container">
            <div className="row clearfix">
              {/* Map Side */}
              <div className="map-column column col-lg-5 col-md-5 col-sm-12 col-xs-12">
                {/* Map Section */}
                <section className="map-section">
                  <div className="address_container">
                    <div className="address-text">
                      <div className="heading_img_container">
                        <img
                          src="entypo_address.png"
                          alt="Address Icon"
                          className="contactus_img"
                        />
                        <p className="contactus_address_heading">Head Office</p>
                      </div>
 
                      <p className="contactus_address">
                        Satadru Technologies Private Limited <br />
                        42/132/A New Ballygunge Road, <br />
                        New Kasba Police Station <br />
                        Kolkata, India 700039
                      </p>
                    </div>
 
                    <div className="address-text">
                      <div className="heading_img_container">
                        <img src="email.png" className="contactus_img" />
                        <p className="contactus_address_heading">Email Id </p>
                      </div>
 
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                          className="contactus_address"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              isMobileDevice()
                                ? "mailto:info@drivershaab.com"
                                : "https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=info@drivershaab.com"
                            )
                          }
                        >
                          info@drivershaab.com{" "}
                        </p>
                        <IconButton
                          sx={{ mt: -1}}
                          onClick={() =>
                            copyToClipBoard("info@drivershaab.com")
                          }
                        >
                          <ContentCopyIcon sx={{fontSize:'13px' }} />
                        </IconButton>
                      </div>
                    </div>
                    <div className="address_text">
                      <div className="heading_img_container">
                        <img src="phone.png" className="contactus_img" />
                        <p className="contactus_address_heading">Contact No.</p>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <p
                          className="contactus_address"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            (window.location.href = "tel:+91 8929000462")
                          }
                        >
                          8929000462
                        </p>
                        <IconButton
                          sx={{ mt: -1}}
                          onClick={() => copyToClipBoard("8929000462")}
                        >
                          <ContentCopyIcon sx={{fontSize:'13px' }} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
 
                  {/* Map Content */}
                </section>
              </div>
 
              {/* Form Column */}
              <div className="form-column column col-lg-7 col-md-7 col-sm-12 col-xs-12">
                {/* Contact Form */}
                <div className="contact-form">
                  {/* Contact Form */}
                  <form id="contact-form" method="post" onSubmit={submit}>
                    <div className="row clearfix">
                      <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        <input
                          style={{ fontSize: "18px" }}
                          type="text"
                          name="username"
                          placeholder="First Name *"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>

                      <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        <input
                          style={{ fontSize: "18px" }}
                          type="text"
                          name="last_name"
                          placeholder="Last Name *"
                          value={last_name}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>
 
                     
                      <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        <input
                          style={{ fontSize: "18px" }}
                          type="email"
                          name="email"
                          placeholder="Email *"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        <input
                          style={{ fontSize: "18px" }}
                          type="text"
                          name="contact"
                          placeholder="Contact No *"
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                          required
                        />
                      </div>


 <div className="col-md-12 col-sm-12 col-xs-12 form-group">
  <select
    style={{ fontSize: "18px" }} // Apply the same font size as the first input
    name="state"
    value={state}
    onChange={(e) => setState(e.target.value)}
    required
    className="form-control" // Using form-control to make it consistent with input styling
  >
    <option value="">State *</option> {/* Default option */}
    {states.map((stateOption) => (
      <option key={stateOption} value={stateOption}>
        {stateOption}
      </option>
    ))}
  </select>
</div>

 <div className="col-md-12 col-sm-12 col-xs-12 form-group">
                        <input
                          style={{ fontSize: "18px" }}
                          type="text"
                          name="city"
                          placeholder="City *"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        />
                      </div>


 
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                        <textarea
                          name="message"
                          required
                          placeholder="Message"
                          style={{
                            fontSize: "18px",
                            borderRadius: "10px",
                          }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
 
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 form-group">
                        <button
                          className="contactus-btn-style-one"
                          type="submit"
                          name="submit-form"
                          // style={{paddingBottom:20}}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* End Contact Form */}
              </div>
            </div>
          </div>
        </div>
        <GoogleMapcom />
        {/* End Contact Section */}
      </div>
    </>
  );
};
 
export default ContactSection;
